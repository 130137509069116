<template>
  <header :class="'fdn-home-header ' + fastClasseHeader">
    <nav class="fd-nav-header fd-nav-site animated">
      <div class="container">
        <div class="d-flex align-items-center">
          <div class="fd-nav-header-brand">
            <a href="/">
              <img
                style="max-height: 70px"
                :src="imageSiteLogoHorizontal"
                class="img-fluid"
              >
            </a>
          </div>
          <div class="fd-nav-header-nav ml-auto">
            <div
              class="fd-nav-header-toggle"
              toggle-menu="toggle-menu"
              @click="eventoMenu"
            >
              <!-- Generator: Adobe Illustrator 19.0.0, SVG Export Plug-In . SVG Version: 6.00 Build 0)  -->
              <svg
                id="Capa_1"
                version="1.1"
                xmlns="http://www.w3.org/2000/svg"
                xmlns:xlink="http://www.w3.org/1999/xlink"
                x="0px"
                y="0px"
                viewBox="0 0 54 54"
                style="enable-background:new 0 0 54 54;"
                xml:space="preserve"
              >
                <circle
                  style="fill:#38454F;"
                  cx="27"
                  cy="27"
                  r="27"
                />

                <line
                  style="fill:none;stroke:#FFFFFF;stroke-width:2;stroke-linecap:round;stroke-miterlimit:10;"
                  x1="15"
                  y1="16"
                  x2="39"
                  y2="16"
                />

                <line
                  style="fill:none;stroke:#FFFFFF;stroke-width:2;stroke-linecap:round;stroke-miterlimit:10;"
                  x1="15"
                  y1="27"
                  x2="39"
                  y2="27"
                />

                <line
                  style="fill:none;stroke:#FFFFFF;stroke-width:2;stroke-linecap:round;stroke-miterlimit:10;"
                  x1="15"
                  y1="38"
                  x2="39"
                  y2="38"
                />

                <g />
                <g />
                <g />
                <g />
                <g />
                <g />
                <g />
                <g />
                <g />
                <g />
                <g />
                <g />
                <g />
                <g />
                <g />
              </svg>
            </div>
            <ul
              id="menu"
              class="fd-nav-header-navlist"
            >
              <li class="fd-nav-header-nav-item">
                <a :href="whatsLink">Fale conosco</a>
              </li>
              <li
                v-if="!usuarioLogado"
                id="itemMinhaConta"
                class="fd-nav-header-nav-item"
              >
                <a
                  href="#"
                  class="btn btn-primary"
                  @click="showModal('modalLogin')"
                >Entrar</a>
              </li>
              <li
                v-if="usuarioLogado"
                id="itemMinhasPlataformas"
                class="fd-nav-header-nav-item"
              >
                <a href="/minhas-plataformas">Minhas plataformas</a>
              </li>
              <li
                v-if="$store.state.fastCarrinho.length"
                class="fd-nav-header-nav-item carrinho"
              >
                <a :href="'/loja-virtual/' + $route.params.id_plataforma + '/carrinho'">
                  <b-icon-cart-fill />
                  Carrinho
                  <label
                    class="badge badge-secondary"
                    count-carrinho
                  >{{ $store.state.fastCarrinho.length }}</label>
                </a>
              </li>
              <li
                v-if="usuarioLogado"
                class="fd-nav-header-nav-item"
              >
                <a
                  class="btn btn-primary"
                  href="#"
                  @click="logout"
                >Sair</a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </nav>
    <!-- /fd-nav-header -->
    <div class="container">
      <div
        v-if="paginaAtual == 'HomeExternoPrincipal'"
        class="fdn-home-header-content"
      >
        <div class="fdn-home-header-content-text">
          <h1>
            EAD para todos
            <small>
              <span>empresas e instituições de ensino</span>
            </small>
            <div class="fdn-home-header-content-text-actions">
              <a
                :href="whatsLink"
                target="_blank"
                class="btn btn-default btn-lg"
              >Fale com um consultor</a>
            </div>
          </h1>
        </div>
        <div class="fdn-home-header-content-image">
          <img
            v-lazy="require('@/assets/eadiwinstar/images/header-people.png')"
            alt="Estudante e Colaborador"
          >
        </div>
      </div>
      <div
        v-else-if="paginaAtual == 'HomeExternoEmpresas'"
        class="fdn-home-empresas"
      >
        <div class="fdn-home-header-content">
          <div class="fdn-home-header-content-text">
            <h1>
              <small>Para</small>
              Empresas
              <small>
                Plataforma EAD para
                <span>treinamento</span> e
                <span>capacitação</span>
                de colaboradores.
              </small>
              <div class="fdn-home-header-content-text-actions">
                <a
                  :href="whatsLink"
                  target="_blank"
                  class="btn btn-default btn-lg"
                >Fale com um consultor</a>
              </div>
            </h1>
          </div>
          <div class="fdn-home-header-content-image">
            <img
              v-lazy="require('@/assets/images/header-pessoa-empresa.png')"
              alt="Estudante e Colaborador"
            >
          </div>
        </div>
      </div>
      <div
        v-else-if="paginaAtual == 'HomeExternoEscolas'"
        class="fdn-home-escolas"
      >
        <div class="fdn-home-header-content">
          <div class="fdn-home-header-content-text">
            <h1>
              <small>Para</small>
              Instituições de ensino
              <small>Plataforma EAD completa para seus alunos.</small>
              <div class="fdn-home-header-content-text-actions">
                <a
                  :href="whatsLink"
                  target="_blank"
                  class="btn btn-default btn-lg"
                >Fale com um consultor</a>
              </div>
            </h1>
          </div>
          <div class="fdn-home-header-content-image">
            <img
              v-lazy="require('@/assets/images/teens-png-4.png')"
              alt="Estudantes"
            >
          </div>
        </div>
      </div>
      <div v-else-if="paginaAtual == 'HomeExternoConvite'">
        <div
          v-if="fastUsuarioConvite.email"
          class="container mt-4"
        >
          <div class="row justify-content-center">
            <div class="col-sm-12 col-md-12 col-lg-9">
              <div class="fd-cadastro-form-header text-center">
                <h3>Bem vindo ao FastEAD!</h3>
                <p
                  v-if="fastUsuarioConvite.administrador == 'S'"
                  class="font-weight-bold"
                >
                  Você foi convidado a participar da plataforma como administrador na plataforma
                  <span
                    class="text-info"
                  >{{ fastUsuarioConvite.nome_plataforma }}</span>
                </p>
                <p
                  v-else-if="fastUsuarioConvite.professor == 'S'"
                  class="font-weight-bold"
                >
                  Você foi convidado a participar da plataforma como professor na plataforma
                  <span
                    class="text-info"
                  >{{ fastUsuarioConvite.nome_plataforma }}</span>
                </p>
                <p
                  v-else
                  class="font-weight-bold"
                >
                  Você foi convidado a participar da plataforma como aluno na plataforma
                  <span
                    class="text-info"
                  >{{ fastUsuarioConvite.nome_plataforma }}</span>
                </p>
              </div>
              <div class="fd-cadastro-form">
                <form
                  id="formConvite"
                  role="form"
                  @submit.prevent="alteraUsuarioConvite()"
                >
                  <div class="form-group">
                    <label for="nome_completo">* Email</label>
                    <input
                      id="email"
                      v-model="fastUsuarioConvite.email"
                      type="text"
                      class="form-control form-control-lg"
                      aria-describedby="email"
                      placeholder="Seu email"
                      required="required"
                      data-error="Digite seu Email"
                      readonly="readonly"
                    >
                    <div
                      class="help-block with-errors"
                      style="color:red"
                    />
                  </div>

                  <div class="row">
                    <div class="col-sm-12 col-md-12 col-lg-6">
                      <div class="form-group">
                        <label for="nome_completo">Primeiro nome</label>
                        <input
                          id="first_name"
                          v-model="fastUsuarioConvite.first_name"
                          type="text"
                          class="form-control form-control-lg"
                          aria-describedby="first_name"
                          placeholder="Seu primeiro nome"
                          required="required"
                          data-error="Digite seu primeiro nome"
                        >
                        <div
                          class="help-block with-errors"
                          style="color:red"
                        />
                      </div>
                    </div>
                    <div class="col-sm-12 col-md-12 col-lg-6">
                      <div class="form-group">
                        <label for="nome_completo">Sobrenome</label>
                        <input
                          id="last_name"
                          v-model="fastUsuarioConvite.last_name"
                          type="text"
                          class="form-control form-control-lg"
                          aria-describedby="last_name"
                          placeholder="Seu sobrenome"
                          data-error="Digite seu sobrenome"
                        >
                        <div
                          class="help-block with-errors"
                          style="color:red"
                        />
                      </div>
                    </div>
                  </div>

                  <div class="row">
                    <div
                      v-if="fastUsuarioConvite.alterar_senha"
                      class="col-sm-12 col-md-12 col-lg-6"
                    >
                      <div class="form-group">
                        <label for="senha">* Defina sua senha para acesso</label>
                        <input
                          id="senha"
                          v-model="fastUsuarioConvite.senha1"
                          type="password"
                          class="form-control form-control-lg"
                          aria-describedby="senhainfo"
                          placeholder="Sua senha"
                          required="required"
                          data-error="Digite sua senha"
                        >
                        <div
                          class="help-block with-errors"
                          style="color:red"
                        />
                      </div>
                    </div>
                    <div
                      v-if="fastUsuarioConvite.alterar_senha"
                      class="col-sm-12 col-md-12 col-lg-6"
                    >
                      <div class="form-group">
                        <label for="senha">* Repita sua senha para acesso</label>
                        <input
                          id="senha2"
                          v-model="fastUsuarioConvite.senha2"
                          type="password"
                          class="form-control form-control-lg"
                          aria-describedby="senhainfo"
                          placeholder="Repita sua senha"
                          required="required"
                          data-error="Repita sua senha"
                        >
                        <div
                          class="help-block with-errors"
                          style="color:red"
                        />
                      </div>
                    </div>
                    <div
                      v-if="fastUsuarioConvite.usuario_cadastrado"
                      class="col-12"
                    >
                      <div class="fd-app-meus-cursos mt-0">
                        <div class="fd-app-curso-aulas-list mt-0">
                          <div
                            class="fd-app-curso-aulas-list-item p-0 shadow-none border-0 bg-transparent d-flex align-items-center"
                          >
                            <label class="switch">
                              <input
                                type="checkbox"
                                checked="checked"
                                @change="fastUsuarioConvite.alterar_senha = !fastUsuarioConvite.alterar_senha"
                              >
                              <span class="slider round" />
                            </label>

                            <label class="ml-2">Alterar senha?</label>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="mt-2 mb-4 text-center">
                    <input
                      id="RegistrarSenhaConvite"
                      type="submit"
                      class="btn btn-danger btn-lg"
                      value="Confirmar convite"
                    >
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div v-else-if="paginaAtual == 'HomeExternoVideos'">
        <div class="fd-app-mode-ead-header fd-galeria-videos-header pt-0">
          <div
            id="divBannerMeusCursos"
            class="fd-app-mode-ead-header-bg p-0"
          >
            <div class="fd-app-mode-ead-header-overlay">
              <div class="container-fluid">
                <div class="row justify-content-between align-items-center">
                  <div class="col-sm-12 col-md-12 col-lg-12 text-dark text-center">
                    <h1>Galeria de Videos</h1>
                    <p>Encontre o video que deseja</p>
                    <div class="fd-app-mode-ead-header-search">
                      <form
                        action="?"
                        methdo="POST"
                        @submit.prevent="getConcurseiroVideosPesquisaSessao(fastPesquisa)"
                      >
                        <input
                          v-model="fastPesquisa"
                          type="text"
                          class="form-control"
                          placeholder="Buscar videos"
                          autocomplete="off"
                        >
                      </form>

                      <img v-lazy="require('@/assets/images/search-icon.svg')">
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="container text-center mt-4">
            <ul class="list-inline">
              <li class="list-inline-item pl-2 pr-2">
                <a href="/videos">
                  <i class="fas fa-home" />
                  <span>Início</span>
                </a>
              </li>
              <li class="list-inline-item pl-2 pr-2">
                <a
                  href="#"
                  @click.prevent="getConcurseiroVideosPesquisa('', 'E')"
                >
                  <i class="fas fa-school" />
                  <span>ENEM</span>
                </a>
              </li>
              <li class="list-inline-item pl-2 pr-2">
                <a
                  href="#"
                  @click.prevent="getConcurseiroVideosPesquisa('', 'V')"
                >
                  <i class="fas fa-graduation-cap" />
                  <span>Vestibular</span>
                </a>
              </li>
              <li class="list-inline-item pl-2 pr-2">
                <a
                  href="#"
                  @click.prevent="getConcurseiroVideosPesquisa('', 'C')"
                >
                  <i class="fas fa-users" />
                  <span>Concursos</span>
                </a>
              </li>
            </ul>
          </div>
        </div>
        <div class="fd-app-wrap">
          <div class="container">
            <div
              v-if="!fastVideosFiltro.length"
              videos-content
              class="fd-galeria-videos m-0"
            >
              <div class="fd-galeria-videos-category ENEM">
                <div class="fd-galeria-videos-category-header">
                  <div class="fd-galeria-videos-category-header-category">
                    <span>ENEM</span>
                  </div>
                  <div class="fd-galeria-videos-category-header-action">
                    <a
                      class="btn btn-primary"
                      href="#"
                      @click.prevent="getConcurseiroVideosPesquisa('', 'E')"
                    >+videos</a>
                  </div>
                </div>
                <div class="fd-galeria-videos-category-list">
                  <div
                    v-for="video in fastVideosEnem"
                    :key="video.id_video"
                  >
                    <div
                      v-lazy:background-image="video.path_thumb"
                      class="fd-galeria-videos-category-list-item"
                      style="background-color: rgb(0, 0, 0); background-size: 60%; background-position: 50% 20%; background-repeat: no-repeat;"
                      @click="exibeVideo(video)"
                    >
                      <div class="fd-galeria-videos-category-list-item-info d-block">
                        <div class="fd-galeria-videos-category-list-item-info-desc">
                          <strong class="text-white">{{ video.rotulo_video }}</strong>
                          <div class="fd-galeria-videos-category-list-item-info-desc-professor">
                            <div
                              class="fd-galeria-videos-category-list-item-info-desc-professor-avatar"
                            />
                            <span class="text-white">{{ video.nome_professor }}</span>
                          </div>
                        </div>
                        <div />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="fd-galeria-videos-category VESTIBULAR">
                <div class="fd-galeria-videos-category-header">
                  <div class="fd-galeria-videos-category-header-category">
                    <span>VESTIBULAR</span>
                  </div>
                  <div class="fd-galeria-videos-category-header-action">
                    <a
                      class="btn btn-primary"
                      href="#"
                      @click.prevent="getConcurseiroVideosPesquisa('', 'V')"
                    >+videos</a>
                  </div>
                </div>
                <div class="fd-galeria-videos-category-list">
                  <div
                    v-for="video in fastVideosVestibular"
                    :key="video.id_video"
                  >
                    <div
                      v-lazy:background-image="video.path_thumb"
                      class="fd-galeria-videos-category-list-item"
                      style="background-color: rgb(0, 0, 0); background-size: 60%; background-position: 50% 20%; background-repeat: no-repeat;"
                      @click="exibeVideo(video)"
                    >
                      <div class="fd-galeria-videos-category-list-item-info d-block">
                        <div class="fd-galeria-videos-category-list-item-info-desc">
                          <strong class="text-white">{{ video.rotulo_video }}</strong>
                          <div class="fd-galeria-videos-category-list-item-info-desc-professor">
                            <div
                              class="fd-galeria-videos-category-list-item-info-desc-professor-avatar"
                            />
                            <span class="text-white">{{ video.nome_professor }}</span>
                          </div>
                        </div>
                        <div />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="fd-galeria-videos-category CONCURSOS">
                <div class="fd-galeria-videos-category-header">
                  <div class="fd-galeria-videos-category-header-category">
                    <span>CONCURSOS</span>
                  </div>
                  <div class="fd-galeria-videos-category-header-action">
                    <a
                      class="btn btn-primary"
                      href="#"
                      @click.prevent="getConcurseiroVideosPesquisa('', 'C')"
                    >+videos</a>
                  </div>
                </div>
                <div class="fd-galeria-videos-category-list">
                  <div
                    v-for="video in fastVideosConcursos"
                    :key="video.id_video"
                  >
                    <div
                      v-lazy:background-image="video.path_thumb"
                      class="fd-galeria-videos-category-list-item"
                      style="background-color: rgb(0, 0, 0); background-size: 60%; background-position: 50% 20%; background-repeat: no-repeat;"
                      @click="exibeVideo(video)"
                    >
                      <div class="fd-galeria-videos-category-list-item-info d-block">
                        <div class="fd-galeria-videos-category-list-item-info-desc">
                          <strong class="text-white">{{ video.rotulo_video }}</strong>
                          <div class="fd-galeria-videos-category-list-item-info-desc-professor">
                            <div
                              class="fd-galeria-videos-category-list-item-info-desc-professor-avatar"
                            />
                            <span class="text-white">{{ video.nome_professor }}</span>
                          </div>
                        </div>
                        <div />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              v-else
              videos-content
              class="fd-galeria-videos m-0"
            >
              <div class="col-12 mb-4 text-center">
                <h4>Disciplinas encontradas ({{ fastVideosFiltro.length }})</h4>
              </div>
              <div
                v-for="(disciplina, index) in fastVideosFiltro"
                :key="index"
                class="fd-galeria-videos-category ENEM"
              >
                <div class="fd-galeria-videos-category-header">
                  <div class="fd-galeria-videos-category-header-category">
                    <span>{{ disciplina[0] }}</span>
                  </div>
                </div>
                <div class="fd-galeria-videos-category-list">
                  <div
                    v-for="video in disciplina[1]"
                    :key="video.id_video"
                  >
                    <div
                      v-lazy:background-image="video.path_thumb"
                      class="fd-galeria-videos-category-list-item"
                      style="background-color: rgb(0, 0, 0); background-size: 60%; background-position: 50% 20%; background-repeat: no-repeat;"
                      @click="exibeVideo(video)"
                    >
                      <div class="fd-galeria-videos-category-list-item-info d-block">
                        <div class="fd-galeria-videos-category-list-item-info-desc">
                          <strong class="text-white">{{ video.rotulo_video }}</strong>
                          <div class="fd-galeria-videos-category-list-item-info-desc-professor">
                            <div
                              class="fd-galeria-videos-category-list-item-info-desc-professor-avatar"
                            />
                            <span class="text-white">{{ video.nome_professor }}</span>
                          </div>
                        </div>
                        <div />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Modal login -->
    <modal
      name="modalLogin"
      height="auto"
      :shift-y="0.1"
      :focus-trap="true"
      :adaptive="true"
    >
      <div class="login-page">
        <div class="form">
          <div
            id="h4FacaSeuLogin"
            class="pl-4 pr-4 pb-4"
          >
            <img
              v-lazy="imageSiteLogoHorizontal"
              style="max-width: 50%; height: auto"
            >
          </div>
          <form
            id="fast-form-login"
            class="login-form"
            @submit="autenticar"
          >
            <input
              id="strEmail"
              v-model="email"
              type="text"
              placeholder="Email"
              required
            >
            <input
              id="strSenha"
              v-model="senha"
              type="password"
              placeholder="Senha"
              required
            >
            <div
              id="msgErroLogin"
              class="small text-danger"
            />
            <button id="btnLoginConta">
              ACESSE SUA CONTA
            </button>
            <p class="message">
              Esqueceu sua senha?
              <a
                href="#"
                @click="showModal('modalRecuperaSenha', 'modalLogin')"
              >Clique para recuperar</a>
            </p>
          </form>
        </div>
      </div>
    </modal>
    <!-- Modal login -->
    <!-- Modal recupera senha -->
    <modal
      name="modalRecuperaSenha"
      height="auto"
      :shift-y="0.1"
      :focus-trap="true"
      :adaptive="true"
    >
      <div class="login-page">
        <div class="form">
          <div
            id="h4FacaSeuLogin"
            class="pl-4 pr-4 pb-4"
          >
            <img
              v-lazy="imageSiteLogoHorizontal"
              style="max-width: 50%; height: auto"
            >
          </div>
          <div class="text-center mt-3 mb-4">
            <h6 class="mb-3">
              Enviar código por email
            </h6>
          </div>
          <div class="con-modal-login-form">
            <div class="con-modal-login-form-inputs p-4">
              <form
                id="fast-form-login"
                class="login-form"
                @submit.prevent="enviarCodigoEmail()"
              >
                <input
                  id="strEmailRecupera"
                  v-model="fastEmailRecupera"
                  class="form-control"
                  type="email"
                  placeholder="e-mail:"
                  required="required"
                >
                <div class="text-center mt-4">
                  <button
                    class="btn btn-primary btn-lg btn-block"
                    type="submit"
                  >
                    Enviar
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </modal>
    <!-- Modal recupera senha -->
    <!-- Modal altera senha -->
    <modal
      name="modalAlteraSenha"
      height="auto"
      :shift-y="0.1"
      :focus-trap="true"
      :adaptive="true"
    >
      <div class="login-page">
        <div class="form">
          <div
            id="h4FacaSeuLogin"
            class="pl-4 pr-4 pb-4"
          >
            <img
              v-lazy="imageSiteLogoHorizontal"
              style="max-width: 50%; height: auto"
            >
          </div>
          <div class="text-center mt-3 mb-4">
            <h6 class="mb-3">
              Alterar senha
            </h6>
          </div>
          <div class="con-modal-login-form">
            <div class="con-modal-login-form-inputs p-4">
              <form
                id="fast-form-login"
                class="login-form"
                @submit.prevent="alterarSenha()"
              >
                <input
                  id="strEmailAltera"
                  v-model="fastAlterarSenha.email"
                  class="form-control"
                  type="email"
                  placeholder="Email"
                  required="required"
                >
                <input
                  id="strSenhaAltera1"
                  v-model="fastAlterarSenha.senha1"
                  class="form-control mt-2"
                  type="password"
                  placeholder="Nova senha"
                  required="required"
                >
                <input
                  id="strSenhaAltera2"
                  v-model="fastAlterarSenha.senha2"
                  class="form-control mt-2"
                  type="password"
                  placeholder="Confirme a nova senha"
                  required="required"
                >
                <div
                  id="msgErroAlteraSenha"
                  class="mt-4"
                />
                <div class="text-center mt-4">
                  <button
                    class="btn btn-primary btn-lg btn-block"
                    type="submit"
                  >
                    Alterar
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </modal>

    <modal
      name="modalExibirVideo"
      :scrollable="true"
      :max-width="992"
      width="100%"
      height="auto"
      :shift-y="0.1"
      :focus-trap="true"
      :adaptive="true"
    >
      <div class="row p-4">
        <div class="col-sm-12 col-md-12 col-lg-6 pl-4 pr-4">
          <img
            v-lazy="imageSiteLogoHorizontal"
            style="max-height: 25px"
            class="img-fluid"
          >
        </div>
        <div class="col-sm-12 col-md-12 col-lg-6 pl-4 pr-4 text-right">
          <a
            class="btn btn-secondary"
            href="#"
            @click.prevent="hideModal('modalExibirVideo')"
          >Fechar</a>
        </div>
        <div class="col-sm-12 col-md-12 col-lg-12 pl-4 pr-4">
          <span class="text-secondary">Está versão é uma demonstração.</span>
        </div>
        <div
          v-if="fastVideoTimer"
          class="col-sm-12 col-md-12 col-lg-12 pl-4 pr-4 pt-2"
        >
          <!-- <vue-plyr>
            <div
              data-plyr-provider="vimeo"
              :data-plyr-embed-id="fastVideoAtual.id_video"
            />
          </vue-plyr> -->
        </div>
        <div
          v-else
          class="col-sm-12 col-md-12 col-lg-12 pl-4 p-4 text-center"
        >
          <h4 class="text-secondary">
            Tempo esgotado
          </h4>
        </div>
      </div>
    </modal>
  </header>
</template>

<script>
import { settings } from "@/config";
import methods from "@/methods";
//import VuePlyr from "vue-plyr";

export default {
  components: {
    //VuePlyr,
  },
  mixins: [methods],
  props: {
    paginaAtual: {
      type: String,
      default: function () {
        return "";
      },
    },
  },
  data: function () {
    return {
      imageSiteLogoHorizontal: "",
      isOpen: false,
      email: "",
      senha: "",
      showModalLogin: false,
      usuarioLogado: false,
      whatsLink: `https://api.whatsapp.com/send?phone=${settings.fastTelefoneContato}&text=Ol%C3%A1%20gostaria%20de%20mais%20informa%C3%A7%C3%B5es%20sobre%20o%20produto`,
      fastUsuarioConvite: {
        administrador: "",
        email: "",
        first_name: "",
        id_plataforma: 0,
        id_usuario: "",
        last_name: "",
        professor: "",
        senha1: "",
        senha2: "",
        nome_plataforma: "",
        alterar_senha: true,
        usuario_cadastrado: false,
      },
      fastClasseHeader: "",
      fastVideosFiltro: [],
      fastVideosTotal: [],
      fastVideosEnem: [],
      fastVideosVestibular: [],
      fastVideosConcursos: [],
      fastVideoAtual: {
        id_video: "",
        rotulo_video: "",
      },
      fastVideoTimer: 60000,
      fastPesquisa: "",
      // Recuperação de senha
      fastEmailRecupera: "",
      fastAlterarSenha: {
        email: "",
        senha1: "",
        senha2: "",
      },
    };
  },
  mounted() {
    settings
      .fastLogoPrincipal()
      .then((e) => (this.imageSiteLogoHorizontal = e.default));

    this.getUrlParameter("login") ? this.showModal("modalLogin") : "";
    this.getUrlParameter("recuperar_senha")
      ? this.showModal("modalRecuperaSenha")
      : "";
    if (this.getUrlParameter("codigo_recuperacao")) {
      this.fastAlterarSenha.email = this.getUrlParameter("email");
      this.showModal("modalAlteraSenha");
    }
    this.validaSessao()
      ? (this.usuarioLogado = true)
      : (this.usuarioLogado = false);
    if (
      this.paginaAtual == "HomeExternoVideos" ||
      this.paginaAtual == "HomeExternoConvite" ||
      this.paginaAtual == "LojaVirtualVitrine" ||
      this.paginaAtual == "LojaVirtualCurso" ||
      this.paginaAtual == "LojaVirtualCarrinho" ||
      this.paginaAtual == "LojaVirtualCheckout" ||
      this.paginaAtual == "LojaVirtualCheckoutFinal"
    ) {
      this.fastClasseHeader = "h-auto bg-image-none";
    } else {
      this.$nextTick(() => {
        this.$store.state.fastCarregando = false;
      });
    }

    if (this.paginaAtual == "HomeExternoVideos") {
      this.getConcurseiroVideosPrincipais("", "E")
        .then(() => this.getConcurseiroVideosPrincipais("", "V"))
        .then(() => this.getConcurseiroVideosPrincipais("", "C"))
        .then(() => {
          if (!this.getFastSessao("sessaoConcurseiroVideo"))
            this.getConcurseiroVideosPesquisaTodos();
          else
            this.fastVideosTotal = this.getFastSessao("sessaoConcurseiroVideo");
        });
    }

    if (this.getUrlParameter("token")) {
      this.verificaTokenConvite(this.getUrlParameter("token"));
    }

    // Verifica sessão carrinho e armazena na store
    if (this.getFastSessao(settings.fastSessaoCarrinho)) {
      this.$store.state.fastCarrinho = this.getFastSessao(
        settings.fastSessaoCarrinho
      );
    }
  },
  methods: {
    eventoMenu() {
      document.querySelector("#menu").classList.toggle("open");
    },
    async autenticar(e) {
      e.preventDefault();

      let form = document.querySelector("#fast-form-login");
      form.btnLoginConta.innerHTML = '<i class="fa fa-spinner fa-spin"></i> Autenticando...';
      let msgErroLogin = document.querySelector("#msgErroLogin");
      msgErroLogin.innerHTML = "";

      // Configurações da requisição ajax
      this.senha = btoa(this.senha);
      let options = {
        headers: {
          "Content-Type": "application/json; charset=utf-8",
        },
        method: "POST",
        body: `username=${this.email}&password=${this.senha}&grant_type=password`,
      };

      // loading
      this.$store.state.fastCarregando = true;
      // Autentica na API e captura o token
      try {
        let resp = await fetch(settings.endApiFastEad + "api/token", options);
        //console.log("Autentica na API e captura o token", resp);
        let json = await resp.json();
        if (!json.error) {
          // Armazena token na sessão
          sessionStorage.setItem(settings.fastSessaoToken, JSON.stringify(json));

          // Busca usuário
          resp = await fetch(settings.endApiFastEad + "api/fast_plataforma_usuario/busca_usuario", this.fastAjaxOptions("GET"));          
          json = await resp.json();
          if (!json.error) {
            // Armazena informações do usuário na sessão
            sessionStorage.setItem(settings.fastSessaoUsuario, json);

            if (this.getUrlParameter("redirect")) {
              window.location.href = settings.endFastEad + this.getUrlParameter("redirect");
            } else {
              // Redireciona para minhas plataformas
              window.location.href = "/minhas-plataformas";
            }
          } else {
            console.log("Erro");
            form.btnLoginConta.innerHTML = "Acesse sua conta";
            msgErroLogin.innerHTML = json.error_description;
            // loading
            this.$store.state.fastCarregando = false;
          }
        } else {
          console.log("Erro");
          form.btnLoginConta.innerHTML = "Acesse sua conta";
          msgErroLogin.innerHTML = json.error_description;
          // loading
          this.$store.state.fastCarregando = false;
        }
      } catch (e) {
        console.log("Erro", e);
        form.btnLoginConta.innerHTML = "Acesse sua conta";
        msgErroLogin.innerHTML = "Login ou senha incorretos";
        // loading
        this.$store.state.fastCarregando = false;
      }

      // Outro método
      /*  fetch("https://localhost:44352/api/token", options).then((resp) => resp.json()).then((obj) => {
          console.log(obj);
          if (obj) {
            sessionStorage.setItem(settings.fastSessaoToken, JSON.stringify(obj));
          }
      }); */
    },
    logout() {
      sessionStorage.clear();
      location.reload();
    },
    // Convite
    async verificaTokenConvite(token) {
      try {
        let resp = await fetch(settings.endApiFastEad + "api/fast_plataforma_usuario/verificaToken?token=" + token, this.fastAjaxOptions("GET"));
        let json = await resp.json();
        let obj = JSON.parse(json)
        console.log("verificaTokenConvite", obj)
        this.$store.state.fastCarregando = false;
        if (!obj.error) {
          this.fastUsuarioConvite = obj
          this.exibeToasty("Token validado com sucesso", "success")
        } else {
          this.exibeToasty(obj.description, "error")
          this.showModal("modalLogin")
        }
      } catch (e) {
        console.log("Erro", e);
        this.exibeToasty("Erro ao recuperar token", "error")
      }
    },
    async alteraUsuarioConvite() {
      console.log("Alterando usuário...");
      let nome_email = "";
      if (this.fastUsuarioConvite.administrador == "S") {
        nome_email = "Administrador aceitou o convite";
      } else {
        if (this.fastUsuarioConvite.professor == "S") {
          nome_email = "Professor aceitou o convite";
        } else {
          nome_email = "Aluno aceitou o convite";
        }
      }
      if (this.fastUsuarioConvite.alterar_senha) {
        let erros = [];
        if (!this.fastUsuarioConvite.first_name) erros.push("O nome é obrigatório");
        if (!this.fastUsuarioConvite.last_name) erros.push("O sobrenome é obrigatório");
        if (!this.fastUsuarioConvite.email) erros.push("O email é obrigatório");
        if (this.fastUsuarioConvite.senha1 != this.fastUsuarioConvite.senha2) erros.push("Senha não conferem");

        if (erros.length) {
          erros.forEach((e) => this.exibeToasty(e, "error"));
        } else {
          console.log("Confirmando convite");
          this.$store.state.fastCarregando = true;
          try {
            let resp = await fetch(settings.endApiFastEad + "api/fast_plataforma_usuario/alteraUsuario?token=" + this.getUrlParameter("token") + "&senha=" + btoa(this.fastUsuarioConvite.senha1) + "&first_name=" + this.fastUsuarioConvite.first_name + "&last_name=" + this.fastUsuarioConvite.last_name + "&id_plataforma=" + this.fastUsuarioConvite.id_plataforma + "&nome_email=" + nome_email, this.fastAjaxOptions("POST"));
            let json = await resp.json();
            let obj = JSON.parse(json)
            console.log(obj)
            if (!obj.error) {
              this.exibeToasty("Convite aceito com sucesso", "success")
              if (this.fastUsuarioConvite.administrador == "S") {
                window.location.href = "/administrador"
              } else if (this.fastUsuarioConvite.professor == "S") {
                window.location.href = "/professor"
              } else if (this.fastUsuarioConvite.aluno == "S") {
                window.location.href = "/aluno"
              } else if (this.fastUsuarioConvite.tesouraria == "S") {
                window.location.href = "/tesouraria"
              } else if (this.fastUsuarioConvite.secretario == "S") {
                window.location.href = "/secretaria"
              } else if (this.fastUsuarioConvite.responsavel == "S") {
                window.location.href = "/responsavel"
              } else {
                this.showModal("modalLogin")
              }
              
            } else {
              this.exibeToasty(obj.description, "error")
              this.$store.state.fastCarregando = false;
            }
            
          } catch (e) {
            console.log("Erro", e);
            this.exibeToasty("Erro ao confirmar convite", "success")
            this.$store.state.fastCarregando = false;
          }
        }
      } else {
        this.$store.state.fastCarregando = true;
        try {
          let resp = await fetch(settings.endApiFastEad + "api/fast_plataforma_usuario/confirmaUsuarioConvite?token=" + this.getUrlParameter("token") + "&id_usuario=" + this.fastUsuarioConvite.id_usuario + "&id_plataforma=" + this.fastUsuarioConvite.id_plataforma + "&nome_email=" + nome_email, this.fastAjaxOptions("POST"));
          let json = await resp.json();
          let obj = JSON.parse(json)
            if (!obj.error) {
              this.exibeToasty("Convite aceito com sucesso", "success")
              if (this.fastUsuarioConvite.administrador == "S") {
                window.location.href = "/administrador"
              } else if (this.fastUsuarioConvite.professor == "S") {
                window.location.href = "/professor"
              } else if (this.fastUsuarioConvite.aluno == "S") {
                window.location.href = "/aluno"
              } else if (this.fastUsuarioConvite.tesouraria == "S") {
                window.location.href = "/tesouraria"
              } else if (this.fastUsuarioConvite.secretario == "S") {
                window.location.href = "/secretaria"
              } else if (this.fastUsuarioConvite.responsavel == "S") {
                window.location.href = "/responsavel"
              } else {
                this.showModal("modalLogin")
              }
            } else {
              this.exibeToasty(obj.description, "error")
              this.$store.state.fastCarregando = false;
            }
        } catch (e) {
          console.log("Erro", e);
          this.exibeToasty("Erro ao confirmar convite", "success")
          this.$store.state.fastCarregando = false;
        }
      }
    },
    // Videoteca
    async getConcurseiroVideosPrincipais(busca, tipo) {
      return new Promise(async (resolve, reject) => {
        try {
          let resp = await fetch(
            settings.endApiFastEad +
              "api/concurseiro_videos/lista_top?busca=" +
              busca +
              "&tipo=" +
              tipo,
            this.fastAjaxOptions("GET")
          );
          let json = await resp.json();
          let obj = JSON.parse(json);
          switch (tipo) {
            case "E":
              this.fastVideosEnem = obj;
              console.log("this.fastVideosEnem", this.fastVideosEnem);
              break;
            case "V":
              this.fastVideosVestibular = obj;
              console.log(
                "this.fastVideosVestibular",
                this.fastVideosVestibular
              );
              break;
            case "C":
              this.fastVideosConcursos = obj;
              console.log("this.fastVideosConcursos", this.fastVideosConcursos);
              break;
            default:
              break;
          }
          resolve(obj);
          //console.log("getConcurseiroVideos", obj);
        } catch (e) {
          reject(Error(e));
        }
      });
    },
    async getConcurseiroVideosPesquisa(busca, tipo) {
      // loading
      this.$store.state.fastCarregando = true;
      return new Promise(async (resolve, reject) => {
        try {
          let resp = await fetch(
            settings.endApiFastEad +
              "api/concurseiro_videos/lista?busca=" +
              busca +
              "&tipo=" +
              tipo,
            this.fastAjaxOptions("GET")
          );
          let json = await resp.json();
          let obj = JSON.parse(json);
          if (obj.length) {
            this.fastVideosFiltro = obj;
            const grouped = this.groupBy(obj, (video) => video.disciplina);
            var videosGroup = Array.from(grouped).sort(Intl.Collator().compare);
            this.fastVideosFiltro = videosGroup;
          } else {
            this.fastVideosFiltro = [];
          }
          // loading
          this.$store.state.fastCarregando = false;
          resolve(obj);
        } catch (e) {
          reject(Error(e));
          // loading
          this.$store.state.fastCarregando = false;
        }
      });
    },
    async getConcurseiroVideosPesquisaTodos() {
      let resp = await fetch(
        settings.endApiFastEad + "api/concurseiro_videos/lista?busca=&tipo=",
        this.fastAjaxOptions("GET")
      );
      let json = await resp.json();
      let obj = JSON.parse(json);
      if (obj.length) {
        this.setFastSessao("sessaoConcurseiroVideo", obj);
        this.fastVideosTotal = obj;
      }

      console.log(
        "Listando sessão",
        this.getFastSessao("sessaoConcurseiroVideo")
      );
    },
    getConcurseiroVideosPesquisaSessao(busca) {
      console.log("Buscando por... " + busca);
      if (this.getFastSessao("sessaoConcurseiroVideo")) {
        let videoFiltrados = this.fastVideosTotal.filter(
          (e) =>
            e.detalhe_video.toLowerCase().indexOf(busca.toLowerCase()) !=
              "-1" ||
            e.disciplina.toLowerCase().indexOf(busca.toLowerCase()) != "-1" ||
            e.rotulo_video.toLowerCase().indexOf(busca.toLowerCase()) != "-1"
        );
        const grouped = this.groupBy(
          videoFiltrados,
          (video) => video.disciplina
        );
        var videosGroup = Array.from(grouped).sort(Intl.Collator().compare);
        this.fastVideosFiltro = videosGroup;
        console.log("Videos filtrados ", this.fastVideosFiltro);
      } else {
        this.getConcurseiroVideosPesquisa(busca, "");
      }
    },
    groupBy(list, keyGetter) {
      const map = new Map();
      list.forEach((item) => {
        const key = keyGetter(item);
        const collection = map.get(key);
        if (!collection) {
          map.set(key, [item]);
        } else {
          collection.push(item);
        }
      });
      return map;
    },
    exibeVideo(video) {
      video.id_video = this.extraiIdVideoVimeo(video.path_video);
      this.fastVideoAtual = video;
      this.showModal("modalExibirVideo");
      this.fastVideoTimer = 60000;
      this.tempoContador(10000);
    },
    extraiIdVideoVimeo(path_video) {
      let link = path_video.split("/");
      let id_video = link[link.length - 1];
      if (link[link.length - 2]) {
        if (link[link.length - 2] == "vimeo.com") {
          id_video = link[link.length - 1];
        } else if (link[link.length - 3] == "vimeo.com") {
          id_video = link[link.length - 2];
        }
      }
      return id_video;
    },
    tempoContador(delay) {
      if (this.fastVideoTimer) {
        console.log("Ainda tem tempo", this.fastVideoTimer);
        this.fastVideoTimer -= delay;
        setTimeout(() => {
          this.tempoContador(delay);
        }, delay);
      } else {
        console.log("Tempo esgotado");
        this.fastVideoTimer = false;
      }
    },
    // Recuperação de senha
    async enviarCodigoEmail() {
      this.$store.state.fastCarregando = true;
      let fast_plataforma_usuario = {
        email: this.fastEmailRecupera,
        id_plataforma: settings.fastIdPlataformaExterno
      };
      try {
        let resp = await fetch(settings.endApiFastEad + "api/fast_plataforma_usuario/recupera_senha", this.fastAjaxOptions("POST", JSON.stringify(fast_plataforma_usuario)));
        let json = await resp.json();            
        let obj = JSON.parse(json)                        
        if (!obj.error) {
          this.hideModal("modalRecuperaSenha");
          this.exibeToasty("Email enviado! Verifique seu email para redefinir sua senha", "success")
          this.fastEmailRecupera = "";
          this.$store.state.fastCarregando = false;
        } else {
          this.exibeToasty(obj.description, "error")
        }
      } catch (e) {
        console.log("Erro", e);
        this.$store.state.fastCarregando = false;
        this.exibeToasty("Erro ao recuperar senha. Contate um administrador do sistema.", "error")
      }
    },
    async alterarSenha() {
      if (this.fastAlterarSenha.senha1 == this.fastAlterarSenha.senha2) {
        this.$store.state.fastCarregando = true;
        try {
          let resp = await fetch(settings.endApiFastEad + "api/fast_plataforma_usuario/altera_senha?codigo=" + this.getUrlParameter("codigo_recuperacao") + "&email=" + this.fastAlterarSenha.email + "&senha=" + btoa(this.fastAlterarSenha.senha1), this.fastAjaxOptions("GET"));
          let json = await resp.json();
          let obj = JSON.parse(json) 
          if (!obj.error) {
            this.showModal("modalLogin", "modalAlteraSenha");
            this.exibeToasty("Senha alterada com sucesso", "success")
            this.fastAlterarSenha = {
              email: "",
              senha1: "",
              senha2: "",
            }
          } else {
            this.exibeToasty(obj.description, "error")
            this.fastAlterarSenha.senha1 = ""
            this.fastAlterarSenha.senha2 = ""
          }
          this.$store.state.fastCarregando = false;
        } catch (e) {
          console.log("Erro", e);
          this.$store.state.fastCarregando = false;
          this.exibeToasty("Erro ao recuperar senha. Contate um administrador do sistema.", "error")
          this.fastAlterarSenha.senha1 = ""
            this.fastAlterarSenha.senha2 = ""
        }
      } else {
        this.exibeToasty("As senhas não coincidem", "error")
      }
    },
  },
};
</script>

<style scoped>
.bg-image-none {
  background-image: none;
}
.login-page {
  width: 100%;
  padding: 0;
  margin: auto;
}
.login-page .form {
  position: relative;
  z-index: 1;
  background: #ffffff;
  max-width: 100%;
  margin: auto;
  padding: 45px;
  text-align: center;
  box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.2), 0 5px 5px 0 rgba(0, 0, 0, 0.24);
}
.login-page .form input {
  font-family: "Roboto", sans-serif;
  outline: 0;
  background: #f2f2f2;
  width: 100%;
  border: 0;
  margin: 0 0 15px;
  padding: 15px;
  box-sizing: border-box;
  font-size: 14px;
}
.login-page .form button {
  font-family: "Roboto", sans-serif;
  text-transform: uppercase;
  outline: 0;
  background: #f18938;
  width: 100%;
  border: 0;
  padding: 15px;
  color: #ffffff;
  font-size: 14px;
  -webkit-transition: all 0.3 ease;
  transition: all 0.3 ease;
  cursor: pointer;
}
.login-page .form button:hover,
.login-page .form button:active,
.login-page .form button:focus {
  background: #f18938;
}
.login-page .form .message {
  margin: 15px 0 0;
  color: #b3b3b3;
  font-size: 12px;
}
.login-page .form .message a {
  color: #f18938;
  text-decoration: none;
}
.login-page .form .register-form {
  display: none;
}
</style>
